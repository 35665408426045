:root {
    font-size: 16px;
}

.Login .error {
    color: red;
    font-size: 12px;
    margin: 0;
    padding: 0;
    position: absolute;
}

.Login .main-error {
    color: red;
    font-size: 16px;
    text-align: center;
}

.Login {
    margin-top: 5vh;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
    width: 100%;
}

.Login-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    min-height: 600px;
}

.Login .login-button {
    margin-top: 1rem;
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
    width: 100%;
    text-align: center;
}

Button:focus {
    border: "none";
}

.Login Button:focus,
.Login Button:active {
    border: "none" !important;
}

.Login label {
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
}

.LoginForm Input {
    width: 100%;
}

.Login-Card {
    border: 0 !important;
    border-radius: 1rem !important;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.Login-Card-Title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
    text-align: center;
}

.Login-Card-Body {
    padding: 2rem;
}
