.aspect-ratio-box {
    background: white;
}
.aspect-ratio-box::before {
    content: "";
    width: 1px;
    /* margin-left: -1px; */
    /* float: left; */
    height: 0;
}
/* .aspect-ratio-box::after {
\    content: "";
    display: table;
    clear: both;
} */
